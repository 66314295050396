module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E4TLNZTP58"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/","/admin/**"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thamizh Songs - Tamil Song Lyrics","short_name":"Thamizh Songs","description":"Your ultimate destination for Tamil song lyrics, featuring the latest and classic Tamil movie songs","start_url":"/","background_color":"#FF4B2B","theme_color":"#FF4B2B","display":"standalone","icon":"src/images/logo/logo.png","icons":[{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"none","lang":"ta","localize":[{"start_url":"/en/","lang":"en","name":"Thamizh Songs - Tamil Song Lyrics","short_name":"Thamizh Songs","description":"Your ultimate destination for Tamil song lyrics, featuring the latest and classic Tamil movie songs"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thamizhsongs.com","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
